import { globalCss } from "@stitches/react";
import { FC } from "react";
import { isMobile } from "react-device-detect";
import { Col, Container, Logo, Row, SubWrite, Text } from "./styled";

const globalStyles = globalCss({
    "*": {
        margin: 0,
        padding: 0,
        color: "#fff9d6",
    }
});

const App: FC = () => {
    globalStyles()
    return (
        <Container mobile={isMobile}>
            <Row mobile={isMobile}>
                <Col justify={'center'} mobile={isMobile}>
                    <Logo mobile={isMobile}/>
                </Col>
                <Col>
                    <Text weight={'bold'} letterSpacing  mobile={isMobile}>
                        ежедневно c 8<sup>00</sup>
                        <br />
                        завтраки <br />с бокалом игристого
                        <br />
                        <br />
                        интересная винная карта
                        <br />
                        <br />
                        авторское меню
                    </Text>
                    <SubWrite letterSpacing transform={'uppercase'}  mobile={isMobile}>г. Уфа, ул. Чернышевского, 88</SubWrite>
                </Col>
            </Row>
        </Container>
    )
}

export default App;