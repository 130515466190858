import { styled } from "@stitches/react";
import logo from "../public/logo.svg";
import background from "../public/background.jpg";

export const Container = styled("div", {
    display: "flex",
    backgroundImage: `url(${background})`,
    backgroundPosition: "center",
    backgroundSize: "cover",

    flexDirection: "column",
    variants: {
        mobile: {
            true: {
                width: "100vw",
                minHeight: "100vh",
                overflowX: "hidden"
            },
            false: {
                height: "100vh",
                alignItems: "center",
                justifyContent: "center",
                overflow: "hidden"
            }
        }
    }
});

export const Row = styled("div", {
    display: "flex",
    variants: {
        mobile: {
            true: {
                flexDirection: "column",
                alignItems: 'center',
                justifyContent:  'center',
                height: '100vh'
            }
        }
    }
});

export const Col = styled("div", {
    display: "flex",
    flexDirection: "column",
    alignItems: 'center',
    justifyContent: 'center',
    variants: {
        mobile: {
            true: {
                width: '100%',
                '&:first-child': {
                    marginRight: 0,
                    marginBottom: 20
                }
            },
            false: {
                width: 380,
                '&:first-child': {
                    marginRight: 60,
                    marginBottom: 0
                }
            },
        },
        justify: {
            center: {
                justifyContent: "center"
            },
            start: {
                justifyContent: "flex-start"
            },
            end: {
                justifyContent: "flex-end"
            },
        }
    }
});

export const Text = styled("div", {
    lineHeight: 1,
    fontFamily: "Hulix",
    variants: {
        mobile: {
            true: {
                fontSize: "2rem",
                textAlign:'center'
            },
            false: {
                fontSize: "3rem",
            },
        },
        weight: {
            "bold": {
                fontWeight: 600,
            }
        },
        transform: {
            uppercase: {
                textTransform: "uppercase"
            }
        },
        letterSpacing: {
            true: {
                letterSpacing: 2,
            }
        }
    }
})

export const SubWrite = styled("div", {
    fontSize: 25,
    lineHeight: 1,
    fontFamily: "Oswald",
    fontWeight: 200,
    variants: {
        mobile: {
            true: {
                fontSize: 15,
                textAlign:'center',
                marginTop: 50,
            },
            false: {
                fontSize: 25,
                marginTop: 100,
            },
        },
        transform: {
            uppercase: {
                textTransform: "uppercase"
            }
        },
        letterSpacing: {
            true: {
                letterSpacing: 2,
            }
        }
    }
})

export const Logo = styled("div", {
    position: "relative",
    content: "",

    backgroundImage: `url(${logo})`,
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundRepeat: 'no-repeat',
    variants: {
        mobile: {
            true: {
                width: 200,
                height: 300,
            },
            false: {
                width: 400,
                height: 600,
            }
        }
    }
})

export const Time = styled("span", {
    display: "flex",
    padding: "0 5px",
    small: {
        fontSize: "1.3rem"
    }
})